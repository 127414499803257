import axios from "axios";
import {
  apiProxyActivityPath,
  apiProxyIdePath,
} from "../../server/src/api/consts";

import * as activity from "../../server/src/api/activity/activityApi";
import * as ide from "../../server/src/api/ide/ideApi";
import { handleAuthError } from "./axios";

// we dont use custom axios with auth check here as activity api can return
// 501 errors randomly sometimes, and we dont want to kick user
// back to login when that happens
export const activityApi = activity.createApiClient(apiProxyActivityPath);

const axiosIde = axios.create();

axiosIde.interceptors.response.use((response) => response, handleAuthError);

export const ideApi = ide.createApiClient(apiProxyIdePath, {
  axiosInstance: axiosIde,
});
